export const GET_POSITION = 'GET_POSITION';
export const GET_POSITION_SUCCESS = 'GET_POSITION_SUCCESS';
export const GET_POSITION_FAILURE = 'GET_POSITION_FAILURE';

export const GET_ALL_USERS = 'GET_ALL_USERS';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_FAILURE = 'GET_ALL_USERS_FAILURE ';

export const POST_POSITION = 'POST_POSITION';
export const POST_POSITION_SUCCESS = 'POST_POSITION_SUCCESS';
export const POST_POSITION_FAILURE = 'POST_POSITION_FAILURE';

export const GET_CANDIDATE = 'GET_CANDIDATE';
export const GET_CANDIDATE_SUCCESS = 'GET_CANDIDATE_SUCCESS';
export const GET_CANDIDATE_FAILURE = 'GET_CANDIDATE_FAILURE';

export const POST_CANDIDATE = 'POST_CANDIDATE';
export const POST_CANDIDATE_SUCCESS = 'POST_CANDIDATE_SUCCESS';
export const POST_CANDIDATE_FAILURE = 'POST_CANDIDATE_FAILURE';

export const POST_BULK_CANDIDATE = 'POST_BULK_CANDIDATE';
export const POST_BULK_CANDIDATE_SUCCESS = 'POST_BULK_CANDIDATE_SUCCESS';
export const POST_BULK_CANDIDATE_FAILURE = 'POST_BULK_CANDIDATE_FAILURE';

export const POST_QUESTION = 'POST_QUESTION';
export const POST_QUESTION_SUCCESS = 'POST_QUESTION_SUCCESS';
export const POST_QUESTION_FAILURE = 'POST_QUESTION_FAILURE';

export const GET_QUESTIONS = 'GET_QUESTIONS';
export const GET_QUESTIONS_SUCCESS = 'GET_QUESTIONS_SUCCESS';
export const GET_QUESTIONS_FAILURE = 'GET_QUESTIONS_FAILURE';

export const DELETE_POSITION = 'DELETE_POSITION';
export const DELETE_POSITION_SUCCESS = 'DELETE_POSITION_SUCCESS';
export const DELETE_POSITION_FAILURE = 'DELETE_POSITION_FAILURE';

export const UPDATE_POSITION_STATUS = 'UPDATE_POSITION_STATUS';
export const UPDATE_POSITION_STATUS_SUCCESS = 'UPDATE_POSITION_STATUS_SUCCESS';
export const UPDATE_POSITION_STATUS_FAILURE = 'UPDATE_POSITION_STATUS_FAILURE';

export const CANCEL_INTERVIEW = 'CANCEL_INTERVIEW';
export const CANCEL_INTERVIEW_SUCCESS = 'CANCEL_INTERVIEW_SUCCESS';
export const CANCEL_INTERVIEW_FAILURE = 'CANCEL_INTERVIEW_FAILURE';

export const GENERATE_LINK = 'GENERATE_LINK';
export const GENERATE_LINK_SUCCESS = 'GENERATE_LINK_SUCCESS';
export const GENERATE_LINK_FAILURE = 'GENERATE_LINK_FAILURE';

export const GENERATE_ANSWER = 'GENERATE_ANSWER';
export const GENERATE_ANSWER_SUCCESS = 'GENERATE_ANSWER_SUCCESS';
export const GENERATE_ANSWER_FAILURE = 'GENERATE_ANSWER_FAILURE';

export const GENERATE_QUESTION = 'GENERATE_QUESTION';
export const GENERATE_QUESTION_SUCCESS = 'GENERATE_QUESTION_SUCCESS';
export const GENERATE_QUESTION_FAILURE = 'GENERATE_QUESTION_FAILURE';

export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';

export const GET_PRODUCT_LIST = 'GET_PRODUCT_LIST';
export const GET_PRODUCT_LIST_SUCCESS = 'GET_PRODUCT_LIST_SUCCESS';
export const GET_PRODUCT_LIST_FAILURE = 'GET_PRODUCT_LIST_FAILURE';

export const GET_NOTIFICATION_LIST = 'GET_NOTIFICATION_LIST';
export const GET_NOTIFICATION_LIST_SUCCESS = 'GET_NOTIFICATION_LIST_SUCCESS';
export const GET_NOTIFICATION_LIST_FAILURE = 'GET_NOTIFICATION_LIST_FAILURE';

export const READ_NOTIFICATION_LIST = 'READ_NOTIFICATION_LIST';
export const READ_NOTIFICATION_LIST_SUCCESS = 'READ_NOTIFICATION_LIST_SUCCESS';
export const READ_NOTIFICATION_LIST_FAILURE = 'READ_NOTIFICATION_LIST_FAILURE';

export const CLEAR_NOTIFICATION_LIST = 'CLEAR_NOTIFICATION_LIST';
export const CLEAR_NOTIFICATION_LIST_SUCCESS = 'CLEAR_NOTIFICATION_LIST_SUCCESS';
export const CLEAR_NOTIFICATION_LIST_FAILURE = 'CLEAR_NOTIFICATION_LIST_FAILURE';

export const GET_USER_PERMISSION = 'GET_USER_PERMISSION';
export const GET_USER_PERMISSION_SUCCESS = 'GET_USER_PERMISSION_SUCCESS';
export const GET_USER_PERMISSION_FAILURE = 'GET_USER_PERMISSION_FAILURE';

export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_FAILURE = 'LOGOUT_USER_FAILURE';

export const GET_DASHBOARD_DETAILS = 'GET_DASHBOARD_DETAILS';
export const GET_DASHBOARD_DETAILS_SUCCESS = 'GET_DASHBOARD_DETAILS_SUCCESS';
export const GET_DASHBOARD_DETAILS_FAILURE = 'GET_DASHBOARD_DETAILS_FAILURE';

export const GET_DOMAIN = 'GET_DOMAIN';
export const GET_DOMAIN_SUCCESS = 'GET_DOMAIN_SUCCESS';
export const GET_DOMAIN_FAILURE = 'GET_DOMAIN_FAILURE';

export const GET_POSITION_LIST = 'GET_POSITION_LIST';
export const GET_POSITION_LIST_SUCCESS = 'GET_POSITION_LIST_SUCCESS';
export const GET_POSITION_LIST_FAILURE = 'GET_POSITION_LIST_FAILURE';

export const GET_WHITE_LABEL = 'GET_WHITE_LABEL';
export const GET_WHITE_LABEL_SUCCESS = 'GET_WHITE_LABEL_SUCCESS';
export const GET_WHITE_LABEL_FAILURE = 'GET_WHITE_LABEL_FAILURE';

export const GET_DROPDOWN_QUESTION = 'GET_DROPDOWN_QUESTION';
export const GET_DROPDOWN_QUESTION_SUCCESS = 'GET_DROPDOWN_QUESTION_SUCCESS';
export const GET_DROPDOWN_QUESTION_FAILURE = 'GET_DROPDOWN_QUESTION_FAILURE';

export const GET_DROPDOWN_POSITION = 'GET_DROPDOWN_POSITION';
export const GET_DROPDOWN_POSITION_SUCCESS = 'GET_DROPDOWN_POSITION_SUCCESS';
export const GET_DROPDOWN_POSITION_FAILURE = 'GET_DROPDOWN_POSITION_FAILURE';

export const GET_CANDIDATE_DETAILS_USING_RESUME = 'GET_CANDIDATE_DETAILS_USING_RESUME';
export const GET_CANDIDATE_DETAILS_USING_RESUME_SUCCESS = 'GET_CANDIDATE_DETAILS_USING_RESUME_SUCCESS';
export const GET_CANDIDATE_DETAILS_USING_RESUME_FAILURE = 'GET_CANDIDATE_DETAILS_USING_RESUME_FAILURE';

export const RESEND_EMAIL = 'RESEND_EMAIL';
export const RESEND_EMAIL_SUCCESS = 'RESEND_EMAIL_SUCCESS';
export const RESEND_EMAIL_FAILURE = 'RESEND_EMAIL_FAILURE';

export const COMPARE_JD_CV = 'COMPARE_JD_CV';
export const COMPARE_JD_CV_SUCCESS = 'COMPARE_JD_CV_SUCCESS';
export const COMPARE_JD_CV_FAILURE = 'COMPARE_JD_CV_FAILURE';

export const RESUME_INTERVIEW = 'RESUME_INTERVIEW';
export const RESUME_INTERVIEW_SUCCESS = 'RESUME_INTERVIEW_SUCCESS';
export const RESUME_INTERVIEW_FAILURE = 'RESUME_INTERVIEW_FAILURE';

export const MANUAL_INVITE = 'MANUAL_INVITE';
export const MANUAL_INVITE_SUCCESS = 'MANUAL_INVITE_SUCCESS';
export const MANUAL_INVITE_FAILURE = 'MANUAL_INVITE_FAILURE';

export const FETCH_RESUME_SCORE = 'FETCH_RESUME_SCORE';
export const FETCH_RESUME_SCORE_SUCCESS = 'FETCH_RESUME_SCORE_SUCCESS';
export const FETCH_RESUME_SCORE_FAILURE = 'FETCH_RESUME_SCORE_FAILURE';

export const MANUAL_CALL_END = 'MANUAL_CALL_END';
export const MANUAL_CALL_END_SUCCESS = 'MANUAL_CALL_END_SUCCESS';
export const MANUAL_CALL_END_FAILURE = 'MANUAL_CALL_END_FAILURE';

export const CODING_PERMISSION = 'CODING_PERMISSION';
export const CODING_PERMISSION_SUCCESS = 'CODING_PERMISSION_SUCCESS';
export const CODING_PERMISSION_FAILURE = 'CODING_PERMISSION_FAILURE';

export const CONVERSATION_PERMISSION = 'CONVERSATION_PERMISSION';
export const CONVERSATION_PERMISSION_SUCCESS = 'CONVERSATION_PERMISSION_SUCCESS';
export const CONVERSATION_PERMISSION_FAILURE = 'CONVERSATION_PERMISSION_FAILURE';

export const APTITUDE_PERMISSION = 'APTITUDE_PERMISSION';
export const APTITUDE_PERMISSION_SUCCESS = 'APTITUDE_PERMISSION_SUCCESS';
export const APTITUDE_PERMISSION_FAILURE = 'APTITUDE_PERMISSION_FAILURE';

export const GET_BANNERS = 'GET_BANNERS';
export const GET_BANNERS_SUCCESS = 'GET_BANNERS_SUCCESS';
export const GET_BANNERS_FAILURE = 'GET_BANNERS_FAILURE';
// ===========================================SKILLS==================================//
export const GET_MANDATORY_SKILL = 'GET_MANDATORY_SKILL';
export const GET_MANDATORY_SKILL_SUCCESS = 'GET_MANDATORY_SKILL_SUCCESS';
export const GET_MANDATORY_SKILL_FAILURE = 'GET_MANDATORY_SKILL_FAILURE';

export const GET_OPTIONAL_SKILL = 'GET_OPTIONAL_SKILL';
export const GET_OPTIONAL_SKILL_SUCCESS = 'GET_OPTIONAL_SKILL_SUCCESS';
export const GET_OPTIONAL_SKILL_FAILURE = 'GET_OPTIONAL_SKILL_FAILURE';

export const FETCH_SKILLS_TITLE = 'FETCH_SKILLS_TITLE';
export const FETCH_SKILLS_TITLE_SUCCESS = 'FETCH_SKILLS_TITLE_SUCCESS';
export const FETCH_SKILLS_TITLE_FAILURE = 'FETCH_SKILLS_TITLE_FAILURE';

export const FETCH_SKILLS = 'FETCH_SKILLS';
export const FETCH_SKILLS_SUCCESS = 'FETCH_SKILLS_SUCCESS';
export const FETCH_SKILLS_FAILURE = 'FETCH_SKILLS_FAILURE';

export const FETCH_SKILLS_JD = 'FETCH_SKILLS_JD';
export const FETCH_SKILLS_JD_SUCCESS = 'FETCH_SKILLS_JD_SUCCESS';
export const FETCH_SKILLS_JD_FAILURE = 'FETCH_SKILLS_JD_FAILURE';
// ===========================================SKILLS==================================//
export const FETCH_LOGO = 'FETCH_LOGO';
export const FETCH_LOGO_SUCCESS = 'FETCH_LOGO_SUCCESS';
export const FETCH_LOGO_FAILURE = 'FETCH_LOGO_FAILURE';

//===========================================CONFIGURATIONS===========================//
export const GET_CONFIGURATIONS = 'GET_CONFIGURATIONS';
export const GET_CONFIGURATIONS_SUCCESS = 'GET_CONFIGURATIONS_SUCCESS';
export const GET_CONFIGURATIONS_FAILURE = 'GET_CONFIGURATIONS_FAILURE';
//===================================CANDIDATE_BANK===================================//

export const CHECK_CANDIDATE_BANK_EXISTS = 'CHECK_CANDIDATE_BANK_EXISTS';
export const CHECK_CANDIDATE_BANK_EXISTS_SUCCESS = 'CHECK_CANDIDATE_BANK_EXISTS_SUCCESS';
export const CHECK_CANDIDATE_BANK_EXISTS_FAILURE = 'CHECK_CANDIDATE_BANK_EXISTS_FAILURE';

export const ADD_CANDIDATE_BANK = 'ADD_CANDIDATE_BANK';
export const ADD_CANDIDATE_BANK_SUCCESS = 'ADD_CANDIDATE_BANK_SUCCESS';
export const ADD_CANDIDATE_BANK_FAILURE = 'ADD_CANDIDATE_BANK_FAILURE';

export const GET_CANDIDATE_BANK = 'GET_CANDIDATE_BANK';
export const GET_CANDIDATE_BANK_SUCCESS = 'GET_CANDIDATE_BANK_SUCCESS';
export const GET_CANDIDATE_BANK_FAILURE = 'GET_CANDIDATE_BANK_FAILURE';

export const EDIT_CANDIDATE_BANK = 'EDIT_CANDIDATE_BANK';
export const EDIT_CANDIDATE_BANK_SUCCESS = 'EDIT_CANDIDATE_BANK_SUCCESS';
export const EDIT_CANDIDATE_BANK_FAILURE = 'EDIT_CANDIDATE_BANK_FAILURE';

export const DELETE_CANDIDATE_BANK = 'DELETE_CANDIDATE_BANK';
export const DELETE_CANDIDATE_BANK_SUCCESS = 'DELETE_CANDIDATE_BANK_SUCCESS';
export const DELETE_CANDIDATE_BANK_FAILURE = 'DELETE_CANDIDATE_BANK_FAILURE';

export const GET_CANDIDATE_BANK_LIST = 'GET_CANDIDATE_BANK_LIST';
export const GET_CANDIDATE_BANK_LIST_SUCCESS = 'GET_CANDIDATE_BANK_LIST_SUCCESS';
export const GET_CANDIDATE_BANK_LIST_FAILURE = 'GET_CANDIDATE_BANK_LIST_FAILURE';

export const DELETE_CANDIDATE_BANK_LIST = 'DELETE_CANDIDATE_BANK_LIST';
export const DELETE_CANDIDATE_BANK_LIST_SUCCESS = 'DELETE_CANDIDATE_BANK_LIST_SUCCESS';
export const DELETE_CANDIDATE_BANK_LIST_FAILURE = 'DELETE_CANDIDATE_BANK_LIST_FAILURE';

export const ADD_EDIT_CANDIDATE_BANK_LIST = 'ADD_EDIT_CANDIDATE_BANK_LIST';
export const ADD_EDIT_CANDIDATE_BANK_LIST_SUCCESS = 'ADD_EDIT_CANDIDATE_BANK_LIST_SUCCESS';
export const ADD_EDIT_CANDIDATE_BANK_LIST_FAILURE = 'ADD_EDIT_CANDIDATE_BANK_LIST_FAILURE';

export const GET_CANDIDATE_BANK_DROPDOWN_LIST = 'GET_CANDIDATE_BANK_DROPDOWN_LIST';
export const GET_CANDIDATE_BANK_DROPDOWN_LIST_SUCCESS = 'GET_CANDIDATE_BANK_DROPDOWN_LIST_SUCCESS';
export const GET_CANDIDATE_BANK_DROPDOWN_LIST_FAILURE = 'GET_CANDIDATE_BANK_DROPDOWN_LIST_FAILURE';

//===========================================Subscription Data=====================================//
export const GET_SUBSCRIPTION_DATA = 'GET_SUBSCRIPTION_DATA';
export const GET_SUBSCRIPTION_DATA_SUCCESS = 'GET_SUBSCRIPTION_DATA_SUCCESS';
export const GET_SUBSCRIPTION_DATA_FAILURE = 'GET_SUBSCRIPTION_DATA_FAILURE';

export const GET_QUESTION_LIBRARY = 'GET_QUESTION_LIBRARY';
export const GET_QUESTION_LIBRARY_SUCCESS = 'GET_QUESTION_LIBRARY_SUCCESS';
export const GET_QUESTION_LIBRARY_FAILURE = 'GET_QUESTION_LIBRARY_FAILURE';

export const GET_LATEST_ACTIVE_POSITIONS = 'GET_LATEST_ACTIVE_POSITIONS';
export const GET_LATEST_ACTIVE_POSITIONS_SUCCESS = 'GET_LATEST_ACTIVE_POSITIONS_SUCCESS';
export const GET_LATEST_ACTIVE_POSITIONS_FAILURE = 'GET_LATEST_ACTIVE_POSITIONS_FAILURE';

export const PUT_FINAL_OUTCOME = 'PUT_FINAL_OUTCOME';
export const PUT_FINAL_OUTCOME_SUCCESS = 'PUT_FINAL_OUTCOME_SUCCESS';
export const PUT_FINAL_OUTCOME_FAILURE = 'PUT_FINAL_OUTCOME_FAILURE';
